<template>
  <div>

    <v-card v-if="isSysadmin" width="370" class="mx-auto mt-5" flat>
      <Errors v-bind:errors="authErrors"/>
    <v-card-title>
      SysAdmin
    </v-card-title>
    <v-card-title>
      Admin for new Institute
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent v-model="valid" class="mt-5">
        <v-text-field 
        type="text" 
        label="InstitutId"
        v-model.trim="signupForm.instituteId"
        :rules="nameRules"
        required
        />

        <v-text-field 
        type="text" 
        label="Vorname"
        autocomplete="firstname"
        v-model.trim="signupForm.firstname"
        :rules="nameRules"
        required
        />

        <v-text-field 
        type="text" 
        label="Nachname" 
        autocomplete="lastname"
        v-model.trim="signupForm.lastname"
        :rules="nameRules"
        required
        />
      
        <v-text-field 
        type="email" 
        label="E-Mail"
        autocomplete="email"
        v-model.trim="signupForm.email"
        :rules="emailRules"
        required
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="mx-2">
      <v-spacer></v-spacer>
      <v-btn 
      color="primary"
      block
      depressed 
      :disabled="!valid"
      @click="signup()"
      >
        <div v-if="!loadSignUp">
          Lehrer für neues Institut registrieren
        </div>
        
        <v-progress-circular
          v-if="loadSignUp"
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
    </v-card>

    <v-card v-if="isSysadmin" width="370" class="mx-auto mt-5" flat>
      <Errors v-bind:errors="authErrors"/>
    <v-card-title>
      Grant Adminrights to user
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent v-model="validGrantAdminRights" class="mt-5">
        <v-text-field 
        type="text" 
        label="InstitutId"
        v-model.trim="grantAdminRightsForm.instituteId"
        :rules="nameRules"
        required
        />

        <v-text-field 
        type="email" 
        label="E-Mail"
        autocomplete="email"
        v-model.trim="grantAdminRightsForm.email"
        :rules="emailRules"
        required
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="mx-2">
      <v-spacer></v-spacer>
      <v-btn 
      color="primary"
      block
      depressed 
      :disabled="!validGrantAdminRights"
      @click="grantAdminRights()"
      >
        <div v-if="!loadGrantAdminRights">
          Zum Admin machen
        </div>
        
        <v-progress-circular
          v-if="loadGrantAdminRights"
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
    </v-card>

    <v-card v-if="!isSysadmin" width="370" class="mx-auto mt-5" flat>
        <Errors v-bind:errors="[{text: 'Du hast nicht die nötigen Rechte um diese Seite zu sehen.'}]"/>
    </v-card>

  </div>
</template>

<script>
import {functions, auth} from '@/firebase';
import Errors from '@/components/Errors.vue';

export default {
  name: 'SysAdmin',

  data() {
    return {
      signupForm: {
        instituteId: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
      },
      grantAdminRightsForm: {
        email: '',
        instituteId: '',
      },
      valid: false,
      validGrantAdminRights: false,
      nameRules: [
        v => !!v || 'Gib einen Namen an.',
        v => v.length <= 20 || 'Der Name muss kürzer als 20 Zeichen sein.',
      ],
      email: '',
      emailRules: [
        v => !!v || 'Gib eine E-mail-Adresse an.',
        v => /.+@.+/.test(v) || 'Gib eine gültige E-Mail-Adresse ein.',
      ],
      roleErrors: [{
        text: 'Du kannst keine neuen Lehrer-Accounts anlegen.', 
        advice:'Bitte einen Administrator deines Instituts einen neuen Lehrer-Account anzulegen.',
      }],
      authErrors: [],
      loadSignUp: false,
      loadGrantAdminRights: false,
      isSysadmin: false,
    };
  },

  components: {
    Errors,
  },

  async created() {
    this.isSysadmin = (await auth.currentUser.getIdTokenResult()).claims.sysAdmin;
  },

  methods: {
    
    async signup() {

        if (this.valid && this.isSysadmin) {
          this.loadSignUp = true;
          console.log('signing up...');
    
          //Get the institueId of the currently logged in user
          let instituteId = this.signupForm.instituteId;
          //let instituteId = 'qta0xwngYmIaQKf9OPdn'; //dummydata for testinstitut
          if(instituteId != ''){
            //add a new user using cloud functions
            const addAdminForNewInstitut = functions.httpsCallable('addAdminForNewInstitut');
             addAdminForNewInstitut({
              instituteId: instituteId, 
              email: this.signupForm.email, 
              firstname: this.signupForm.firstname, 
              lastname: this.signupForm.lastname
            }).then(result => {
              this.loadSignUp = false;
              this.authErrors = [];
              console.log(result);
              auth.sendPasswordResetEmail(this.signupForm.email);
            }).catch(error => {
              this.loadSignUp = false;
              this.authErrors.push(
                {
                  text: error.message,
                }
              )
            });
          } else {
            this.loadSignUp = false;
            throw 'This user does not have an instituteId. You cannot add a new user without an instituteId.';
          }
    
          
          
        }
    },

    async grantAdminRights() {

        if (this.validGrantAdminRights && this.isSysadmin) {
          this.loadGrantAdminRights = true;
          console.log('granting admin rights...');
    
          //add a new user using cloud functions
          const grantAdminRights = functions.httpsCallable('grantAdminRights');
           grantAdminRights({
            email: this.grantAdminRightsForm.email,
            instituteId: this.grantAdminRightsForm.instituteId,
          }).then(result => {
            this.loadGrantAdminRights = false;
            this.authErrors = [];
            console.log(result);
          }).catch(error => {
            this.loadGrantAdminRights = false;
            this.authErrors.push(
              {
                text: error.message,
              }
            )
          })
        }
    }
  }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>